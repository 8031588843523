<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12
      //- 搜尋bar
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 篩選查詢 
              i.fe-settings.ml-1
            a.collapse-turn.mr-2(@click="visible = !visible", href="javascript: void(0);")
              span(v-if="visible") 收起 
                i.fe-chevron-up
              span(v-else) 展開 
                i.fe-chevron-down
            b-button.ml-1.width-lg(variant="primary" @click="onSearch") 查詢
            b-button.ml-1.width-lg(variant="secondary" @click="onResetSearch") 清除
        //- 搜尋bar
        b-collapse#customaccordion-1(v-model="visible", accordion="accordion", role="tabpanel")
          b-card-body
            form.form-horizontal(role="form")
              .row
                .col-lg-4
                  .form-group.mb-3
                    label 用戶編號
                    br
                    input.form-control(v-model="search.code", type="text", placeholder="請輸入用戶編號")
                .col-lg-4
                  .form-group.mb-3
                    label 姓名
                    br
                    input.form-control(v-model="search.name", type="text", placeholder="請輸入用戶姓名")
                .col-lg-4
                  .form-group.mb-3
                    label 手機號碼
                    br
                    input.form-control(v-model="search.cellphone", type="text",placeholder="請輸入手機號碼")
              .row
                .col-lg-4
                  .form-group.mb-3
                    label Email
                    br
                    .row
                      .col-6.d-flex.align-items-center
                        input.form-control(v-model="search.emailPrefix", type="text")
                        span.email-control @
                      .col-6
                        input.form-control(v-model="search.emailSuffix", type="text")
                .col-lg-4
                  .form-group.mb-3
                    label 好朋友等級
                    br
                    multiselect(
                      v-model="search.level", 
                      :options="friendsLevel", 
                      placeholder="請選擇等級", 
                      :multiple="false",
                      track-by="id"
                      label="name"
                      select-label=""
                    )
                .col-lg-4
                  .form-group.mb-3
                    label 帳戶狀態
                    br
                    multiselect(
                      v-model="search.status", 
                      :options="accountStatus", 
                      placeholder="請選擇啟用狀態", 
                      :multiple="false",
                      track-by="id"
                      label="name"
                      select-label=""
                    )
      //- 表格
      .card
        .card-body
          .header-row.mb-3
            .header-title 用戶查詢結果
            p.mr-3.mt-2 符合條件資料共計有 {{ totalRows }} 筆資料
            b-button.width-lg(variant="primary" @click="onExport") 匯出
          //- Table
          .row
            .col-12
              b-table(
                head-variant="light"
                ref="searchTable"
                :items="searchList", 
                :fields="fields", 
                responsive,
                :per-page="perPage",
                :current-page="currentPage",
                :sort-desc.sync="sortDesc",
                :filter="filter",
                :filter-included-fields="filterOn",
                @filtered="onFiltered")

                template(v-slot:cell(user_name)="data")
                  span {{ data.item.user_name || '-' }}

                template(v-slot:cell(user_phone)="data")
                  span {{ data.item.user_phone || '-' }}

                template(v-slot:cell(user_email)="data")
                  span {{ data.item.user_email || '-' }}

                template(v-slot:cell(type)="data")
                  span(v-if="data.item.type === 1") 中天好朋友
                  span(v-else-if="data.item.type === 2") 麻吉好朋友
                  span(v-else-if="data.item.type === 3") 超級好朋友
                  span(v-else) -

                template(v-slot:cell(status)="data")
                  b-button(
                    v-if="data.item.status === 0",
                    variant="warning",
                    size="sm"
                  ) 停權
                  b-button(
                    v-else-if="data.item.status === 1",
                    variant="success",
                    size="sm"
                  ) 正常
                  b-button(
                    v-else-if="data.item.status === 3", 
                    variant="danger", 
                    size="sm"
                  ) 封鎖
                  span(v-else) -

                template(v-slot:cell(action)="data")
                  b-button.width-lg(variant="primary" @click="onClickDetail(data.item.id)") 查看

          .row.mb-md-2
            .col-6
              div(id="tickets-table_length", class="dataTables_length")
                label.d-inline-flex.align-items-center
                  | Show
                  b-form-select.ml-2.mr-2(v-model="perPage", size="sm", :options="pageOptions")
                  | entries
            .col-6
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { tableData } from "../data";


/**
 * Starter component
 */
export default {
  head () {
    return {};
  },
  data () {
    return {
      title: "用戶查詢",
      items: [
        {
          text: "用戶/等級任務管理",
          href: "/usermanagement",
        },
        {
          text: "用戶查詢",
          active: true,
        }
      ],
      search: {
        code: '',
        name: '',
        cellphone: '',
        emailPrefix: '',
        emailSuffix: '',
        level: null,
        status: null,
      },
      visible: true,
      friendsLevel: [
        {
          id: 1,
          name: "中天好朋友",
        },
        {
          id: 2,
          name: "麻吉好朋友",
        },
        {
          id: 3,
          name: "超級好朋友"
        }
      ],
      accountStatus: [
        {
          id: 1,
          name: "正常"
        },
        {
          id: 2,
          name: "停權"
        },
        {
          id: 3,
          name: "封鎖"
        }
      ],

      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        {
          key: "user_name",
          label: "姓名",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "user_phone",
          label: "手機號碼",
          sortable: true,
          class: 'width-auto center'
        },
        {
          key: "user_email",
          label: "Email",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "type",
          label: "好朋友等級",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "status",
          label: "帳戶狀態",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "last_login",
          label: "最近一次登入時間",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "action",
          label: "",
          sortable: false,
          stickyColumn: true,
          class: 'width-auto center'
        }
      ],
    };
  },
  computed: {
    rows () {
      return this.tableData.length;
    },
  },
  mounted () {
    this.reloadList()
  },
  methods: {
    searchList (_table, _callback) {
      const vm = this

      const searchParam = {
        page: this.currentPage || 1,
        code: this.search?.code || null,
        user_name: this.search?.name || null,
        user_phone: this.search?.cellphone || null,
        email_account: this.search?.emailPrefix || null,
        email_domain: this.search?.emailSuffix || null,
        type: this.search?.level?.id || null,
        status: this.search?.status?.id || null,
      }
      this.$root.apis.getMemberList(searchParam,
        function (_response) {
          let response = _response.body.data
          vm.totalRows = response.total
          vm.currentPage = response.page
          vm.perPage = response.per_page
          if (typeof _callback === 'function') {
            vm.tableData = response.words
            _callback(vm.tableData)
          }
        },
        function (_error) {
          console.error(_error)
          if (typeof _callback === 'function') {
            _callback([])
          }
        })
    },
    reloadList () {
      this.$refs.searchTable.refresh()
    },
    checkSearchData () {
      // 用戶編號格式
      const isValidUserCode = /^[A-Z][A-Z0-9]\d{8}$/;
      if (this.search.code && !isValidUserCode.test(this.search.code)) {
        this.$root.common.showErrorDialog('會員編號填寫不完整！')
        return false
      }
      // 手機格式
      const isValidUserPhone = /^09\d{8}$/;
      if (this.search.cellphone && !isValidUserPhone.test(this.search.cellphone)) {
        this.$root.common.showErrorDialog('手機號碼填寫不完整！')
        return false
      }
      // email 必須同時填寫
      if ((this.search.emailPrefix && !this.search.emailSuffix) || (!this.search.emailPrefix && this.search.emailSuffix)) {
        this.$root.common.showErrorDialog('email 填寫不完整！')
        return false
      }
      return true
    },
    onSearch () {
      if (!this.checkSearchData()) return
      this.reloadList()
    },
    onResetSearch () {
      this.search = {
        code: '',
        name: '',
        cellphone: '',
        emailPrefix: '',
        emailSuffix: '',
        level: null,
        status: null,
      }
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onClickDetail (_userId) {
      this.$router.push({ path: `/usermanagement/setting/${_userId}` })
    },
    onExport () {
      window.open(`${process.env.VUE_APP_BACKEND_BASE_URL}/export/member-list`, '_blank')
    }
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  }
};
</script>

<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem 
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)    
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)

.email-control
  margin-left: 12px
  margin-right: -12px

::v-deep  .b-table-sticky-column:last-child
  right: 0
  position: sticky !important
  background: #f1f5f7

::v-deep td
  &.b-table-sticky-column:last-child
    background: #fff !important

::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
</style>
